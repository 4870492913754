import { Link } from "react-router-dom";
import restricted_image from "../assets/images/undraw_pedestrian_crossing_l-6-jv.svg";

export const RestrictedAccess = () => {
  return (
    <div className="flex flex-col justify-center items-center h-screen w-full font-medium text-black-400  text-base">
      <img
        src={restricted_image}
        alt="img"
        height={300}
        className="mb-50 w-[350px] max-sm:w-[300px]"
      />
      <p className="text-center text-sm  mt-10 max-sm:mt-10 max-sm:px-10 max-sm:text-gray-700">
        Ohh, you just got logged out! Due to security reason only one session is
        allowed at a time<br></br> If you think you have only one session active
        currently kindly report us at <br></br> <br />
        <Link
          className="ml-0 bg-gray-200 px-4 py-4 rounded-lg font-normal text-sm"
          to="mail:support@reproneetlearning.com"
        >
          support@reproneetlearning.com
        </Link>
      </p>
    </div>
  );
};
