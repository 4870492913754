import React from "react";
import Watermark from "@uiw/react-watermark";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import "./pdfview.css";
import { useParams } from "react-router-dom";

export const PDFViewer = () => {
  const { url, deviceType } = useParams();
  console.log(deviceType, url);
  const pdfURL = `https://d3an0lws29lp3a.cloudfront.net/data/chapter_file/files/${url}`;
  const docs = [
    {
      uri: pdfURL,
    },
  ];

  return (
    <Watermark
      content={["Reproneet Learning ", "Dr. Monalisa Singh"]}
      style={{ background: "#fff" }}
      onMouseDown={(e) => e.preventDefault()}
      onContextMenu={(e) => e.preventDefault()}
    >
      <div>
        <DocViewer
          config={{
            header: {
              disableHeader: false,
              disableFileName: false,
              retainURLParams: false,
            },
            csvDelimiter: ",", // "," as default,
            pdfZoom: {
              defaultZoom: deviceType === "mobile" ? 1.2 : 0.5, // 1 as default,
            },

            pdfVerticalScrollByDefault: true, // false as default
          }}
          prefetchMethod="GET"
          documents={docs}
          pluginRenderers={DocViewerRenderers}
          initialActiveDocument={docs[1]}
        />
        ;
      </div>
    </Watermark>
  );
};

// import React from "react";
// import { Document, Page, pdfjs } from "react-pdf";
// import { useParams } from "react-router-dom";

// pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

// export const PDFViewer = () => {
//   const { url } = useParams();
//   const pdfURL = `https://d3an0lws29lp3a.cloudfront.net/data/chapter_file/files/${url}`;

//   return (
//     <div>
//       <Document file={pdfURL}>
//         <Page pageNumber={5} />
//       </Document>
//     </div>
//   );
// };

// // return <div>{renderPDF()}</div>;
