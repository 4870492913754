import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isDarkModeEnabled: false,
  nowPlaying: {},
  currentChapter: {},
};

const appSlice = createSlice({
  name: "appReducer",
  initialState,
  reducers: {
    darkMode(state, action) {
      state.isDarkModeEnabled = action.payload;
    },
    nowPlaying(state, action) {
      state.nowPlaying = action.payload;
    },
    currentVideo(state, action) {
      state.currentChapter = action.payload;
    },
  },
});

export const { darkMode, nowPlaying, currentVideo } = appSlice.actions;
export default appSlice.reducer;
