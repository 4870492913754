import { useDispatch, useSelector } from "react-redux";
import {
  AttemptConstant,
  Circle,
  HorizontalPill,
  SemiCircle,
} from "./AttemptType";
import {
  updateCurrentQuestion,
  updateQuestionAttemptState,
} from "../redux/QuestionReducer";
import * as Color from "@mui/material/colors";
export const ExamSideBar = () => {
  const { loading, currentQuestion, questionsData } = useSelector((state) => {
    return state?.questionReducer;
  });
  const dispatch = useDispatch();

  const handleClick = (itemId) => {
    dispatch(updateCurrentQuestion(itemId));
    dispatch(updateQuestionAttemptState(AttemptConstant.MARKED));
  };

  return (
    <div className="border h-screen w-1/4 bg-blue-100 p-4 rounded-lg flex flex-col mt-[5px] max-md:w-full max-md:h-[150px] max-md:mt-2 max-md:mb-2">
      <p className=" flex  text-gray-800 text-lg font-semibold max-md:px-2 max-md:text-sm">
        Question Map
      </p>

      <div className="p-2 overflow-scroll flex flex-wrap max-md:flex-nowrap max-md:overflow-y-hidden">
        {questionsData?.questionRequest?.map((item, id) => {
          switch (item.questionState) {
            case AttemptConstant.PRESTINE:
              return (
                <HorizontalPill
                  callback={handleClick}
                  key={item?.questionId}
                  questionId={id}
                  backgroundColor="red"
                />
              );
            case AttemptConstant.ANSWERED:
              return (
                <Circle
                  callback={handleClick}
                  key={id}
                  questionId={id}
                  backgroundColor="green"
                />
              );
            case AttemptConstant.MARKED:
              return (
                <SemiCircle
                  callback={handleClick}
                  key={id}
                  questionId={id}
                  backgroundColor="purple"
                />
              );
            default:
              return <div>Unexplained </div>;
          }
        })}
      </div>
    </div>
  );
};

export default ExamSideBar;
