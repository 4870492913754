import { blue } from "@mui/material/colors";

export const AttemptConstant = {
  PRESTINE: "prestined",
  ANSWERED: "answered",
  MARKED: "marked",
  NOT_VISITED: "not_visited",
  NOT_ANSWERED: "not_answered",
  MARKED_AND_ANSWERED: "marked_answered",
};

export const SemiCircle = ({
  questionId,
  backgroundColor,
  callback,
  isSelected = true,
}) => {
  return (
    <div>
      <div
        onClick={() => callback(questionId)}
        style={{
          height: 50,
          width: 50,
          padding: 12,
          margin: 10,
          textAlign: "center",
          alignContent: "center",
          borderRadius: "150px 150px 0 0",
          backgroundColor: isSelected ? backgroundColor : "white",
          color: isSelected ? "white" : backgroundColor,
        }}
      >
        {questionId}
      </div>
    </div>
  );
};

export const Circle = ({ questionId, backgroundColor }) => {
  return (
    <div
      className={`flex h-50 w-50 p-4 rounded-full  font-medium text-white bg-green-900 text-center align-center justify-center m-3`}
      // style={{
      //   height: 50,
      //   width: 50,
      //   padding: 12,
      //   color: "white",
      //   margin: 10,
      //   justifyContent: "center",
      //   alignContent: "center",
      //   textAlign: "center",
      //   borderRadius: "50%",
      //   backgroundColor: backgroundColor,
      // }}
    >
      <p className="self-center">{questionId}</p>
    </div>
  );
};

export const HorizontalPill = ({
  questionId,
  backgroundColor,
  callback,
  isSelected = false,
}) => {
  return (
    <div
      onClick={() => callback(questionId)}
      style={{
        height: 50,
        width: 50,
        padding: 12,
        margin: 10,
        color: isSelected ? backgroundColor : "black",
        justifyContent: "center",
        alignContent: "center",
        textAlign: "center",
        borderRadius: 12,
        border: `1px solid ${isSelected ? backgroundColor : "white"}`,
        backgroundColor: isSelected ? backgroundColor : "white",
      }}
      className=""
    >
      {questionId + 1}
    </div>
  );
};
