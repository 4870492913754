import { useDispatch, useSelector } from "react-redux";
import { fetchEnrolledCourses } from "../redux/enrolledCourse";
import store from "../redux/store";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { fetchCourseDetail } from "../redux/courseDetailSlice";
import { Box, CircularProgress } from "@mui/material";
import { Toolbar } from "../toolbar/toolbar";

export const ClassRoom = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userSlice, enrolledSlice, courseDetail } = useSelector(
    (store) => store
  );

  if (courseDetail.enrolledCourseDetail.length > 0) {
    navigate("/learning/start");
  }

  useEffect(() => {
    store.dispatch(
      fetchEnrolledCourses({
        userId: userSlice.userInfo.userId,
        courseStatus: "APPROVED",
      })
    );
  }, []);

  const viewCourse = (enrolledCourseId) => {
    console.log(enrolledCourseId);
    if (enrolledCourseId.isExpired) {
      return;
    }
    store.dispatch(
      fetchCourseDetail({
        courseId: enrolledCourseId,
        userId: userSlice.userInfo.userId,
      })
    );
  };

  return (
    <div
      style={{
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Toolbar />
      {courseDetail.loading && (
        <div
          component="span"
          className="absolute flex self-center justify-center content-center z-[3]"
        >
          <CircularProgress />
        </div>
      )}
      {enrolledSlice?.enrollment?.length > 0 && (
        <p className="px-48 text-3xl max-sm:px-10 max-sm:text-xl text-gray-700 font-semibold mt-20">
          Enrolled Courses
        </p>
      )}
      {enrolledSlice?.enrollment?.length > 0 &&
        enrolledSlice?.enrollment?.map((enrolledCourse) => {
          return enrolledCourse.courseListStatus ? (
            <div className="flex justify-center relative items-center">
              <div
                className={`relative classItem flex content-center self-center px-10 py-10 ${
                  enrolledCourse?.isExpired ? "blur-sm disabled" : ""
                } mt-10 rounded-lg bg-[#f9f9f9] h-50 w-[80%]  scale-70 max-sm:flex-col max-sm:text-xs max-sm:px-6 max-sm:py-2 border `}
                onClick={() => viewCourse(enrolledCourse.courseId)}
              >
                <img
                  alt="course"
                  src={enrolledCourse.thumbnail}
                  className="rounded-lg self-center h-20 w-20 max-sm:hidden"
                />
                <div className="ml-20 max-sm:ml-0">
                  <div
                    style={{
                      fontStyle: "bold",
                      marginBottom: 10,
                    }}
                  >
                    <div className="text-lg font-bold text-[#454545]">
                      {enrolledCourse.title}
                    </div>
                  </div>
                  <div> {enrolledCourse.description}</div>
                </div>
              </div>
              {enrolledCourse?.isExpired && (
                <p className="absolute text-lg font-semibold text-red-500">
                  Course Expired
                </p>
              )}
            </div>
          ) : null;
        })}
      {enrolledSlice?.enrollment?.length === 0 && (
        <div className="flex mt-3 flex-col justify-center content-center items-center">
          <img
            src={require("../assets/images/empty-space.png")}
            width={"20%"}
            style={{
              alignSelf: "center",
            }}
            alt="img"
          />
          <div className="self-center text-center mt-10 bg-red-100 mx-2 px-2 py-4 rounded-lg text-sm font-medium">
            Hmm! Seems like you have not purchased any course. Buy our courses
            to start learning :-)
          </div>
        </div>
      )}
    </div>
  );
};
