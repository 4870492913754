import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./toolbarStyle.css";
import { useState } from "react";
import { setClearCourse } from "../redux/courseDetailSlice";
export const Toolbar = () => {
  const { userInfo, loading, error } = useSelector((state) => state.userSlice);
  const [isMenuVisible, setMenuVisible] = useState(false);

  const dispatch = useDispatch();
  const clearState = () => {
    dispatch(setClearCourse());
  };
  return (
    <div className="fixed flex w-full items-center px-10 bg-transparent z-[10]  top-0 max-md:pt-6 ">
      <img
        src={require("../assets/images/reproneet Background Removed.png")}
        alt="logo"
        srcset=""
        width={80}
        height={85}
        className="max-md:w-[40px] translate-y-2"
      />

      <div className=" flex w-full flex justify-end h-10 max-sm:hidden z-10">
        <ul>
          <Link to="/" title="Home">
            Home
          </Link>
        </ul>
        <ul>
          <Link to="/courses" title="Courses">
            Courses
          </Link>
        </ul>

        {userInfo.userId != null && (
          <ul>
            <Link to="/classroom" onClick={clearState} title="Classroom">
              Classroom
            </Link>
          </ul>
        )}
        {userInfo.userId == null ? (
          <ul>
            <Link to="/login" title="Login">
              Login
            </Link>
          </ul>
        ) : (
          <ul>
            <Link to="/logout" title="Logout">
              Logout
            </Link>
          </ul>
        )}
      </div>
      <div
        className="absolute right-0 top-5 right-5 sm:hidden"
        onClick={() => {
          setMenuVisible(!isMenuVisible);
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={2.5}
          stroke={isMenuVisible ? "purple" : "grey"}
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
          />
        </svg>
      </div>
      {isMenuVisible && (
        <div class="absolute right-12 top-8 rounded-lg bg-white text-start shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] ">
          <div class="border-b-2 border-neutral-100 px-6 py-3  dark:text-50">
            <Link
              to="/"
              title="Home"
              className="text-base font-normal   text-grey-200 "
            >
              Home
            </Link>
          </div>
          <div class="border-t-2 border-neutral-100 px-6 py-3  dark:text-50 ">
            <Link
              to="/courses"
              title="Courses"
              className="text-base text-grey-200"
            >
              Courses
            </Link>
          </div>
          <div class="border-t-2 border-neutral-100 px-6 py-3  dark:text-50">
            <Link
              onClick={clearState}
              to="/classroom"
              title="Classroom"
              className="text-base text-grey-200"
            >
              Classroom
            </Link>
          </div>

          <div className="border-t-2 border-neutral-100 px-6 py-3  dark:text-50">
            {userInfo.userId == null ? (
              <ul className="px-0 dark:text-50">
                <Link
                  to="/login"
                  title="Login"
                  className="text-base text-grey-200"
                >
                  Login
                </Link>
              </ul>
            ) : (
              <ul className=" px-0 dark:text-50">
                <Link
                  to="/logout"
                  title="Logout"
                  className="text-base text-grey-200"
                >
                  Logout
                </Link>
              </ul>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

const style = {};
