import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { updateQuestionAttemptState } from "../redux/QuestionReducer";
import { AttemptConstant } from "./AttemptType";

export const AnswerView = () => {
  const dispatch = useDispatch();
  const { loading, currentQuestion, questionsData } = useSelector((state) => {
    return state?.questionReducer;
  });

  const handleChange = () => {
    dispatch(updateQuestionAttemptState(AttemptConstant.ANSWERED));
  };

  return (
    <div className="w-1/2 h-full mt-[5px] mb-[5px] overflow-scroll mr-[px] rounded-lg bg-gray-50 max-md:w-full">
      <p className="px-10 mt-10 mb-5 text-lg font-semibold text-gray-700 max-md:text-lg">
        Choose correct option::
      </p>
      <div className="px-5 flex flex-col font-normal">
        <div>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="female"
            name="radio-buttons-group"
            onChange={handleChange}
          >
            {questionsData?.questionRequest?.[
              currentQuestion
            ].optionRequest?.map((options) => {
              return (
                <FormControlLabel
                  // sx={{ fontSize: "12px" }}
                  componentsProps={{ typography: { variant: "" } }}
                  fontSize={10}
                  value={options?.optionId}
                  control={<Radio />}
                  label={options?.optionName}
                />
              );
            })}
          </RadioGroup>
        </div>
        <div className="mt-10 text-gray-800">
          <p className="font-semibold max-md:text-sm mb-4">Correct Option :</p>
          {questionsData?.questionRequest?.[currentQuestion]?.questionState ==
            AttemptConstant.ANSWERED && (
            <div>
              <p className="max-md:text-sm">
                {
                  questionsData?.questionRequest?.[currentQuestion]
                    ?.correctAnswer?.optionName
                }
              </p>
              <p className="mt-4 mb-4 font-semibold max-md:text-sm">
                Description:
              </p>
              <p className="max-md:text-sm text-justify">
                {
                  questionsData?.questionRequest?.[currentQuestion]
                    ?.correctAnswer?.description
                }
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
