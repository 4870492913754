import "./PrivacyPolicy.css";

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-cotainer">
      <h1>Privacy Policy</h1>
      <h2>1. Information We Collect</h2>
      <p>
        1.1 Personal Information: When you use our Website and App, we may
        collect personal information that you voluntarily provide to us, such as
        your name, email address, contact information, and other details you
        choose to provide when registering or using our services.
      </p>
      <p>
        1.2 Usage Information: We may also collect non-personal information
        about your use of the Website and App, including your IP address,
        browser type, operating system, device information, pages visited, and
        actions taken.
      </p>
      <p>
        1.3 Cookies and Similar Technologies: We may use cookies and similar
        technologies to enhance your experience on the Website and App. Cookies
        are small text files that are placed on your device to collect and store
        certain information. You can control the use of cookies through your
        browser settings.
      </p>

      <h2>2. Use of Information</h2>
      <p>
        2.1 Providing Services: We may use the information we collect to provide
        you with our services, process your requests, and communicate with you
        regarding your account and any updates or changes to our services.
      </p>
      <p>
        2.2 Improving User Experience: We may use the information to analyze
        user behavior, track usage patterns, and improve the functionality and
        content of our Website and App.
      </p>
      <p>
        2.3 Marketing and Communications: With your consent, we may send you
        promotional materials, updates, and newsletters related to our services.
        You can opt-out of receiving such communications at any time.
      </p>
      <p>
        2.4 Legal Compliance: We may use and disclose your information to comply
        with applicable laws, regulations, legal processes, or enforceable
        governmental requests.
      </p>

      <h2>3. Data Sharing and Disclosure</h2>
      <p>
        3.1 Service Providers: We may share your information with trusted
        third-party service providers who assist us in operating our Website and
        App, providing services, or conducting business on our behalf. These
        service providers are obligated to protect your information and can only
        use it for the purposes specified by us.
      </p>
      <p>
        3.2 Business Transfers: In the event of a merger, acquisition, or sale
        of our business assets, your information may be transferred to the
        acquiring entity or parties involved, subject to the same privacy
        commitments as outlined in this policy.
      </p>
      <p>
        3.3 Legal Requirements: We may disclose your information if required to
        do so by law or in good faith belief that such action is necessary to
        comply with legal obligations, protect our rights, or investigate fraud
        or security issues.
      </p>

      <h2>4. Data Security</h2>
      <p>
        We employ industry-standard security measures to protect your
        information from unauthorized access, disclosure, alteration, or
        destruction. However, please note that no method of transmission over
        the internet or electronic storage is completely secure, and we cannot
        guarantee absolute security.
      </p>

      <h2>5. Third-Party Links</h2>
      <p>
        Our Website and App may contain links to third-party websites or
        services. This Privacy Policy does not apply to those third-party
        websites or services, and we are not responsible for their privacy
        practices. We encourage you to review the privacy policies of those
        third parties before providing any personal information.
      </p>

      <h2>6. Changes to this Privacy Policy</h2>
      <p>
        We reserve the right to modify or update this Privacy Policy from time
        to time. Any changes will be effective upon posting the revised policy
        on our Website or App. We encourage you to review this Privacy Policy
        periodically to stay informed about our data practices.
      </p>
      <h2>7. Children Policy</h2>
      <p>
        We do not knowingly collect personal information from children under the
        age of 13. Our app is not intended for use by children under 13 years
        old. If you are a parent or guardian and believe that your child has
        provided us with personal information, please contact us immediately so
        that we can take appropriate action to remove such information from our
        records.
      </p>

      <h2>8. Contact Us</h2>
      <p>
        If you have any questions, concerns, or requests regarding this Privacy
        Policy, please contact us at support@reproneetlearning.com.
      </p>

      <p>
        By using our Website and App, you acknowledge that you have read,
        understood, and agreed to this Privacy Policy.
      </p>
    </div>
  );
};

export { PrivacyPolicy };
