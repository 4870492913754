import { useLocation } from "react-router-dom";
import { fetchQuestionForCourse } from "../redux/QuestionReducer";
import { AnswerView } from "./AnswerView";
import ExamSideBar from "./ExamSideBar";
import { QuestionView } from "./QuestionView";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Toolbar } from "../toolbar/toolbar";
import Watermark from "@uiw/react-watermark";
const TestScreen = (props) => {
  const dispatch = useDispatch();
  let location = useLocation();
  useEffect(() => {
    dispatch(fetchQuestionForCourse({ chapterId: location?.state?.chapterId }));
  }, []);
  return (
    <div
      style={{
        width: "99%",
        display: "flex",
        padding: "1px",
        flexDirection: "column",
        borderRadius: "10px",
      }}
    >
      <Toolbar />
      <Watermark
        // image={require("../assets/images/reproneet Background Removed.png")}
        content={["Reproneet Learning ", "Dr. Monalisa Singh"]}
        style={{ background: "#fff" }}
      >
        <div
          // style={{
          //   marginTop: "2em",
          //   display: "flex",
          //   flexDirection: "row",
          //   borderRadius: "10px",
          // }}
          className="flex mt-2 max-md:flex-col-reverse max-md: mt-0"
        >
          <div className="flex max-md:flex-col">
            {/* <SemiCircle questionId={10} backgroundColor="red" /> */}
            {/* <HorizontalPill questionId={10} backgroundColor="red" /> */}
            <QuestionView />
            <AnswerView />
          </div>
          <ExamSideBar />
        </div>
      </Watermark>
    </div>
  );
};

export default TestScreen;
