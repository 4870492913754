export const AppConstant = {
  BASE_URL:
    process.env.REACT_APP_ENV === "staging"
      ? "https://dev-api.reproneetlearning.com"
      : "https://student.reproneetlearning.com",
  SERVICES_BASE_URL:
    process.env.REACT_APP_ENV === "staging"
      ? "https://dev-services.reproneetlearning.com"
      : "https://services.reproneetlearing.com",

  SIGN_UP: "/v1/api/user/signup",
  SAVE_USER_DETAIL: "/v1/api/user/saveuserdetails",
};
