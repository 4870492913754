import * as React from "react";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function MediaCard({
  courseId,
  title,
  description,
  thumbnail,
  totalDuration,
  courseDurationPriceResponse,
}) {
  const { userInfo } = useSelector((state) => state.userSlice);
  const navigate = useNavigate();

  // const displayRazorpay = async () => {
  //   if (userInfo.userId == null || userInfo.userId === "undefined") {
  //     navigate("/login");
  //     return;
  //   }

  //   const url =
  //     process.env.REACT_APP_ENV === "production"
  //       ? "https://services.reproneetlearning.com/service/payment/orders"
  //       : "https://dev-services.reproneetlearning.com/service/payment/orders";
  //   const body = {
  //     amount: courseDurationPriceResponse[0].price * 100,
  //     currency: "INR",
  //     userId: userInfo.userId,
  //     courseId: courseId,
  //     duration: courseDurationPriceResponse[0].duration,
  //   };
  //   const result = await axios.post(url, body);

  //   const { amount, id: order_id, currency } = result.data;

  //   const options = {
  //     key:
  //       process.env.REACT_APP_ENV === "staging"
  //         ? process.env.REACT_APP_DEVELOPMENT_API_KEY_RZR_PAY
  //         : process.env.REACT_APP_PROD_API_KEY_RZR_PAY, // Enter the Key ID generated from the Dashboard
  //     amount: amount,
  //     currency: currency,
  //     name: "Reproneet Learning.",
  //     description: "Course Purchase",
  //     order_id: order_id,
  //     handler: async function (response) {
  //       const data = {
  //         orderCreationId: order_id,
  //         razorpayPaymentId: response.razorpay_payment_id,
  //         razorpayOrderId: response.razorpay_order_id,
  //         razorpaySignature: response.razorpay_signature,
  //         userId: userInfo.userId,
  //         courseId: courseId,
  //         duration: courseDurationPriceResponse[0].duration,
  //       };

  //       const result = await axios.post(
  //         process.env.REACT_APP_ENV === "staging"
  //           ? "https://dev-services.reproneetlearning.com/service/payment/success"
  //           : "https://services.reproneetlearning.com/service/payment/success",
  //         data
  //       );

  //       alert(result.data.msg);
  //     },
  //     prefill: {
  //       name: userInfo.firstName + " " + userInfo.lastName,
  //       email: userInfo.email,
  //       contact: userInfo.contact,
  //     },

  //     theme: {
  //       color: "#61dafb",
  //     },
  //   };

  //   const paymentObject = new window.Razorpay(options);
  //   paymentObject.open();
  // };

  const getCourseDetails = () => {
    navigate("/courseInfo", {
      state: {
        title,
        description,
        courseId,
        thumbnail,
        totalDuration,
        courseDurationPriceResponse,
      },
    });
  };

  return (
    <div className=" shadow-lg flex  flex-col w-1/5 mx-4 h-auto mb-4 max-md:w-96 px-2 py-2 rounded-lg">
      <div className="w-full  flex justify-center max-md:w-full ">
        <img
          alt="img"
          src={thumbnail}
          className="self-center  w-full  max-md:scale-10 max-md:h-[250px] rounded-lg"
        />
      </div>
      <div className="px-4 py-10">
        <p className="text-xl mt-2 font-semibold text-gray-600 max-md:text-base">
          {title}
        </p>

        <div className="h-max-[220px]  overflow-hidden text-xs mt-4 max-md:text-xs">
          {description}
        </div>
      </div>
      <CardActions>
        <Button size="small" onClick={() => getCourseDetails()}>
          View More
        </Button>
      </CardActions>
    </div>
  );
}
