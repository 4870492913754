import { useDispatch, useSelector } from "react-redux";
import { currentVideo, nowPlaying } from "../redux/AppStateReducer";
import ReactPlayer from "react-player";
import { Link, useNavigate } from "react-router-dom";
import { Toolbar } from "../toolbar/toolbar";
import { Box, Modal, Typography } from "@mui/material";
import { setClearCourse, setResources } from "../redux/courseDetailSlice";
import Watermark from "@uiw/react-watermark";
import { useEffect, useState } from "react";
import { Accordion, AccordionItem } from "@szhsin/react-accordion";

export const LearningScreen = () => {
  const dispatch = useDispatch();
  const {
    userSlice: { userInfo },
    courseDetail: { enrolledCourseDetail },
    appState: { nowPlaying, currentChapter },
  } = useSelector((state) => state);

  const [showDescription, setShowDescription] = useState(false);

  useEffect(() => {
    return () => {
      console.log("Hello");
      // dispatch(setClearCourse());
    };
  }, []);

  return userInfo.userId != null ? (
    <div className="w-full h-screen bg-gray-50 overflow-x-hidden">
      <Toolbar />

      <div>
        <div className="flex max-md:flex-col max-md:h-screen mt-16  md:mt-40">
          <div className="mx-2 my-2 w-[75%] h-screen max-md:w-96 max-md:h-auto self-center">
            <VideoPlayer />

            <div className="text-lg mb-20 mt-20 font-semibold max-md:text-base max-md:w-full max-md:mt-10 max-md:mb-10 max-md:px-4 max-md:mb-2 max-md:mt-2 ">
              {nowPlaying?.name}- {nowPlaying?.briefDescription}
            </div>
            <div>
              <p className="flex justify-between text-[#454545] text-base font-bold max-md:text-sm max-md:px-4 py-2">
                {enrolledCourseDetail[0]?.title}
                <p
                  className="text-red-700"
                  onClick={() => setShowDescription(!showDescription)}
                >
                  {showDescription ? "Show Less" : "Show More"}
                </p>
              </p>
            </div>
            {showDescription && (
              <div className="hover:ease-in duration-200  overflow-hidden ">
                <p
                  className={`hover:ease-in duration-200   max-md:text-xs text-[#454545] max-md:px-4  overflow-hidden ${
                    showDescription ? "max-h-96" : "max-h-0"
                  }`}
                >
                  {enrolledCourseDetail[0]?.description}
                </p>
              </div>
            )}
          </div>
          <Sidebar />
        </div>
      </div>
    </div>
  ) : (
    <div>
      <div
        onClick={() => {
          //   navigatoe("/login");
        }}
        className="w-full h-screen  flex content-center self-center items-center text-lg justify-center"
      >
        <Link to="/login">Ahh! Your session is Expired, Please login</Link>
      </div>
    </div>
  );
};

const VideoPlayer = () => {
  const {
    courseDetail: { enrolledCourseDetail },
    appState: { nowPlaying, currentChapter },
  } = useSelector((state) => state);

  return (
    <div className="flex justify-center ">
      {(currentChapter.url === "" || currentChapter.url === undefined) && (
        <div className="w-full h-full flex content-center justify-center self-center max-md:h-[240px]  ">
          <img
            src={enrolledCourseDetail?.[0].thumbnail}
            alt=""
            srcset=""
            className="self-center border-2 border-[neutal-500] rounded-xl scale-90"
          />
        </div>
      )}
      <Watermark
        // image={require("../assets/images/reproneet Background Removed.png")}
        content={["Reproneet Learning ", "Dr. Monalisa Singh"]}
        style={{
          background: "#fff",
          justifyContent: "center",
          alignSelf: "center",
          alignItems: "center",
        }}
      >
        <ReactPlayer
          stopOnUnmount={true}
          thumbnail={currentChapter.image}
          width="100%"
          height={"auto"}
          playing
          config={{ file: { attributes: { controlsList: "nodownload" } } }}
          controls={true}
          url={currentChapter.url}
        />
      </Watermark>
    </div>
  );
};

const Sidebar = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const {
    courseDetail: { enrolledCourseDetail },
  } = useSelector((state) => state);

  const playCurrentLecture = (chapter) => {
    dispatch(nowPlaying(chapter));
  };
  const playVideo = (video) => {
    dispatch(currentVideo(video));
  };

  return (
    <div className="w-[35%] h-[90vh] mt-2 overflow-y-scroll  overflow-x-hidden  mr-1 px-1 py-1 max-md:w-full max-md:mt-0 flex justify-center">
      {/* <h1>Course Content</h1> */}

      <div>
        {enrolledCourseDetail[0]?.sectionResponse.map((section) => {
          return (
            <Accordion transition transitionTimeout={250} className="mt-2">
              <AccordionItem
                allowMultiple={false}
                header={
                  <>
                    <p className="py-3 px-6  w-[23rem]  md:w-[28rem]   self-start border-[1px]  font-semibold text-gray-500 text-xs   md:text-base text-start rounded-lg  ">
                      {"Section " + section.sectionName}
                    </p>
                  </>
                }
              >
                <Accordion className="w-full ">
                  {section.chapterResponse?.map((chapter) => {
                    return (
                      <AccordionItem
                        allowMultiple={false}
                        className="w-full "
                        header={
                          <>
                            <div className="w-[23rem] md:w-[28rem]  self-center flex  justify-between  py-4 px-4 border-2 border-neutral-100 mt-3 bg-orange-200 rounded-lg ">
                              <p className=" font-medium  text-xs md:text-sm max-md:mt-2 text-start overflow-hidden ">
                                {"Chapter    "}
                                {chapter.name}{" "}
                              </p>
                              <div className="flex flex-col items-end justify-end w-[8rem]">
                                <p
                                  className="text-blue-700 text-[10px] md:text-sm font-semibold "
                                  onClick={() =>
                                    navigate("/test", {
                                      state: {
                                        chapterId: chapter?.chapterId,
                                      },
                                    })
                                  }
                                >
                                  View Questions
                                </p>
                                <p
                                  onClick={() => {
                                    console.log(chapter?.fileResponsePdf);
                                    dispatch(
                                      setResources(chapter?.fileResponsePdf)
                                    );
                                  }}
                                  className="font-bold text-[10px] md:text-sm text-purple-900"
                                >
                                  View Notes
                                </p>
                              </div>
                            </div>
                          </>
                        }
                      >
                        <div
                          onClick={() => playCurrentLecture(chapter)}
                          className="videoItem px-1 py-1  rounded-bl-lg rounded-br-lg bg-orange-100  max-md:py-4 max-md:px-3 w-[23rem] md:w-[28rem] "
                        >
                          {/* TODO */}
                          <div className="">
                            {chapter.fileResponseVideo?.map((video, index) => {
                              return (
                                <div className="px-2 mx-1 py-2 mt-4 flex self-center items-center content-center border-2  rounded-lg text-xs max-md:px-2 max-md:py-4">
                                  <img
                                    width={20}
                                    style={{ marginRight: 10 }}
                                    src={require("../assets/images/play-btn.png")}
                                    alt="play icon"
                                    className="max-md:w-4"
                                  />
                                  <div className="flex justify-between ">
                                    <div onClick={() => playVideo(video)}>
                                      {"  " +
                                        video?.fileName?.replace(".mp4", "")}
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </AccordionItem>
                    );
                  })}
                </Accordion>
              </AccordionItem>
            </Accordion>
          );
        })}
      </div>
      <ShowResources />
    </div>
  );
};

const ShowResources = () => {
  const {
    userSlice: { userInfo },
    courseDetail,
    appState: { nowPlaying, currentChapter },
  } = useSelector((state) => {
    console.log("Resouces::", state?.courseDetail?.resources.length);
    return state;
  });
  const navigate = useNavigate();

  const dispatch = useDispatch();
  return (
    <Modal
      className="flex items-center justify-center"
      open={courseDetail?.resources.length > 0}
      onClose={() => dispatch(setResources([]))}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="flex flex-col w-1/3 bg-white px-6 py-4 rounded-lg card border max-md:w-96">
        <p className="font-semibold text-gray-700">Lecture Notes</p>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {courseDetail?.resources?.map((pdf, index) => {
            return (
              <div
                onClick={() => {
                  console.log(pdf);
                  const index = pdf?.url?.lastIndexOf("/");

                  window.open(
                    `https://www.reproneetlearning.com/content-viewer/${pdf?.url?.substring(
                      index + 1
                    )}/web`,
                    "_blank"
                  );
                }}
                style={{
                  cursor: "pointer",
                  width: "100%",
                  justifyContent: "center",
                  alignContent: "center",
                  borderRadius: 10,
                  fontFamily: "Lato",
                  fontWeight: 700,
                  marginTop: 20,
                  color: "#454545",
                  textTransform: "capitalize",
                  background: "#f8f8f8",
                }}
              >
                <Typography id="modal-modal-description" sx={{ p: 2 }}>
                  {index + 1}
                  {").  "}
                  {pdf?.fileName?.replace(".pdf", "")}
                </Typography>
              </div>
            );
          })}
        </div>
      </div>
    </Modal>
  );
};
