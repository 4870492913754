import axios from "axios";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

export const CourseDetails = (props) => {
  const { state } = useLocation();
  const navigate = useNavigate();
  console.log(state);
  const { userInfo } = useSelector((state) => state.userSlice);

  const displayRazorpay = async (duration, price) => {
    if (userInfo.userId == null || userInfo.userId === "undefined") {
      navigate("/login");
      return;
    }

    const url =
      process.env.REACT_APP_ENV === "production"
        ? "https://services.reproneetlearning.com/service/payment/orders"
        : "https://dev-services.reproneetlearning.com/service/payment/orders";
    const body = {
      amount: price * 100,
      currency: "INR",
      userId: userInfo.userId,
      courseId: state.courseId,
      duration: duration,
    };
    const result = await axios.post(url, body);

    const { amount, id: order_id, currency } = result.data;

    const options = {
      key:
        process.env.REACT_APP_ENV === "production"
          ? process.env.REACT_APP_PROD_API_KEY_RZR_PAY
          : process.env.REACT_APP_DEVELOPMENT_API_KEY_RZR_PAY, // Enter the Key ID generated from the Dashboard
      amount: amount,
      currency: currency,
      name: "Reproneet Learning.",
      description: "Course Purchase " + state.title,
      order_id: order_id,
      handler: async function (response) {
        const data = {
          orderCreationId: order_id,
          razorpayPaymentId: response.razorpay_payment_id,
          razorpayOrderId: response.razorpay_order_id,
          razorpaySignature: response.razorpay_signature,
          userId: userInfo.userId,
          courseId: state.courseId,
          duration: duration,
        };

        try {
          const result = await axios.post(
            process.env.REACT_APP_ENV === "production"
              ? "https://services.reproneetlearning.com/service/payment/success"
              : "https://dev-services.reproneetlearning.com/service/payment/success",
            data
          );
          navigate("/payment-verification", {
            state: {
              status: "success",
              data,
              courseName: state.title,
            },
          });
        } catch (err) {
          navigate("/payment-verification", {
            state: {
              status: "failure",
              data,
            },
          });
        }
      },

      prefill: {
        name: userInfo.firstName + " " + userInfo.lastName,
        email: userInfo.email,
        contact: userInfo.contact,
      },

      theme: {
        color: "#61dafb",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };

  return (
    <div className=" w-screen h-screen flex justify-center  items-center">
      <div className="w-[34rem] bg-neutral-100 h-auto flex flex-col  rounded-lg drop-shadow-sm px-6 py-6 border ">
        <img
          src={state.thumbnail}
          alt="thumbnail"
          className="scale-90  rounded-2xl self-center h-[320px]"
        />
        <p className=" px-10 text-2xl font-bold text-gray-700">{state.title}</p>
        {state.description.split("•").map((description) => (
          <p className="px-10 py-2 text-start font-medium text-gray-800">
            {description}
          </p>
        ))}
        <div className="flex flex-row w-full overflow-x-scroll  ">
          {state.courseDurationPriceResponse.map((item) => (
            <div className=" w-[250px] h-[260px] flex flex-col mx-4  mt-4 px-10  rounded-3xl py-10 drop-shadow-lg bg-white  mb-2 scale-90">
              <div className="w-[250px]">
                <img
                  alt="book_img"
                  src={require("../assets/images/book.png")}
                  className="w-[60px] mb-5"
                />
                <p className="text-2xl font-bold text-gray-700">
                  Rs {item.price}
                </p>
                <p className="text-lg font-bold text-gray-700">
                  Valid For {item.duration} Days
                </p>
                <button
                  className="self-start hover:cursor-pointer mt-2 px-4 py-2 text-white rounded-full bg-purple-900 text-sm"
                  onClick={() => displayRazorpay(item.duration, item.price)}
                >
                  Buy Now
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
