import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { AppConstant } from "../.environments";

const initialState = {
  userInfo: {},
  loading: false,
  error: "",
  errorMessage: "",
};

const LoginThunk = createAsyncThunk("login/loginUser", (payload) => {
  return axios
    .post(`${AppConstant.BASE_URL}/v1/api/user/signin`, payload)
    .then((res) => {
      return res.data.responseData;
    });
});

const userSlice = createSlice({
  name: "userReducer",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(LoginThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(LoginThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.userInfo = action.payload;
      state.error = "";
    });
    builder.addCase(LoginThunk.rejected, (state, action) => {
      state.loading = false;
      state.courses = [];
      console.log(action);
      state.error = action.error.message;
      // state.errorMessage=action.
    });
  },
});

export default userSlice.reducer;
export { LoginThunk };
