import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { json } from "react-router-dom";
import { AppConstant } from "../.environments";

const initialState = {
  enrollment: [],
  loading: false,
  error: "",
};

const fetchEnrolledCourses = createAsyncThunk(
  "enrollment/fetchEnrolledCourses",
  (payload) => {
    return axios
      .get(
        `${AppConstant.BASE_URL}/v1/api/courses/getcourses-by-status?userId=${payload.userId}&courseStatus=${payload.courseStatus}`
      )
      .then((res) => {
        return res.data.responseData.courseResponse;
      });
  }
);

const enrollmentSlice = createSlice({
  name: "enrolledCourseReducer",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchEnrolledCourses.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchEnrolledCourses.fulfilled, (state, action) => {
      state.loading = false;
      state.enrollment = action.payload;
      state.error = "";
    });
    builder.addCase(fetchEnrolledCourses.rejected, (state, action) => {
      state.loading = false;
      state.enrollment = [];
      state.error = action.error.message;
    });
  },
});

export default enrollmentSlice.reducer;
export { fetchEnrolledCourses };
