import { useEffect } from "react";
import { Toolbar } from "../toolbar/toolbar";
import { fetchCourses } from "../redux/courseSlice";
import store from "../redux/store";
import { useSelector } from "react-redux";
import { ReactSVG } from "react-svg";
import person from "../assets/images/5245473-person-ai.svg";
import background from "../assets/images/bg-home-ai.svg";
import { socket } from "../App";
import { Link } from "react-router-dom";

export const HomeScreen = () => {
  const { userInfo, loading, error } = useSelector((state) => state.userSlice);

  useEffect(() => {
    if (userInfo.userId != null) {
      socket.emit("onlineUser", {
        payload: {
          platform: "web",
          device: "browser",
        },
        userId: userInfo.userId,
      });
    }
  }, []);

  const { courses } = useSelector((state) => state.courseSlice);

  return (
    <div className="h-screen w-screen top-0 mt-[-5px] p-0 absolute bg-hero-pattern bg-no-repeat bg-cover flex flex-col bg-[#ffffff] ">
      <div className="w-4/5 absolute h-30 mt-60  mx-20 flex  flex-col justify-center max-sm:mx-10 lg:mx-60 ">
        <div className="flex ">
          <p className="font-bold text-gray-600 text-5xl mt-10 max-sm:text-2xl max-sm:mt-1 ">
            BEST ONLINE ACADEMIC <br></br> INSTITUTE FOR POST GRADUATES
            <br></br> IN OBSTETRICS & GYNECOLOGY
          </p>
          <img
            width={"40%"}
            src={person}
            alt="background"
            className="mt-auto mb-auto absolute right-0 max-sm:hidden md:hidden lg:block"
          />
        </div>
        <p className="w-2/5 mt-10 max-sm:w-full max-sm:text-sm">
          A perfect forum for post graduates in OBG to prepare for various
          national level entrance exams – NEET SS, FET, INISSET, PDCET; and to
          prepare for University exams in obstetrics and gynecology
          (MD/MS/DNB/DGO)
        </p>
        <div className="mt-12 max-sm:w-full">
          <Link
            to={
              "https://play.google.com/store/apps/details?id=com.foxlabz.edulabz"
            }
            className="bg-purple-500 px-6 py-4 m-0 text-white rounded-full hover:bg-purple-700 hover:ease-in duration-200  max-sm:px-3 max-sm:py-2 max-sm:text-xs  "
          >
            Download for Android
          </Link>
          <Link
            top=""
            className="bg-purple-500 px-6 py-4  m-4 text-white rounded-full hover:bg-purple-700 hover:ease-in duration-200 max-sm:px-3 max-sm:py-2 max-sm:text-xs"
          >
            Download for iOS
          </Link>
        </div>
      </div>
      <Toolbar />
      <div className="absolute bottom-5 text-center flex w-full justify-center">
        <Link to="https://www.phynlabz.com" className="text-sm text-gray-500">
          Powered By &copy;PhynLabz Tech
        </Link>
      </div>
    </div>
  );
};
