import Lottie from "lottie-react";
import success from "../assets/images/animation_llhnavl5.json";
import failure from "../assets/images/animation_llhsa9ka.json";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";

export const PaymentStatus = () => {
  const { state } = useLocation();
  const navigator = useNavigate();
  useEffect(() => {
    if (state !== null) {
      setTimeout(() => {
        if (state?.status === "success") {
          navigator("/classroom");
        } else {
          navigator("/courses");
        }
      }, 4000);
    }
  }, []);
  console.log("Payment Innfo", state);
  return state !== null ? (
    <div className="w-full h-screen flex  flex-col justify-center items-center">
      {state?.status === "success" ? (
        <div>
          <p className="text-2xl font-medium text-center">
            Congratulations, You have been enrolled in<br></br>{" "}
            {state.courseName}
          </p>

          <div className="w-[500px] flex flex-col justify-center items-center">
            <Lottie animationData={success} loop={true} />;
            <p className="text-2xl">Payment Successful</p>
            <p className="text-xl font-bold mt-10">
              Yayy!! Now you can navigate to classromm section to view your
              purchases course
            </p>
          </div>
        </div>
      ) : (
        <div className="flex-col  items-center">
          <p className="text-3xl font-semibold text-center">
            We could not capture your payment!!<br></br>
          </p>

          <div className="w-[500px] flex flex-col justify-center items-center">
            <Lottie animationData={failure} loop={true} />;
            <p className="text-2xl">Your Payment was unsuccessful </p>
          </div>
        </div>
      )}
    </div>
  ) : (
    <div className="h-screen w-full flex justify-center items-center">
      <p className="text-2xl">Restricted Access</p>
    </div>
  );
};

export default PaymentStatus;
