import { useSelector } from "react-redux";

export const QuestionView = () => {
  const { loading, currentQuestion, questionsData } = useSelector((state) => {
    return state?.questionReducer;
  });
  return (
    <div className="w-1/2 h-full mt-[5px] rounded-lg px-6 py-6 max-md:w-full max-md:min-h-[200px] max-md:mt-2 border">
      <p className="font-semibold text-gray-900">Question Description</p>
      <div className="pt-50 text-gray-700  mt-10 ">
        <p className="text-lg max-md:text-sm">
          {questionsData?.questionRequest?.[currentQuestion]?.questionName}
        </p>
      </div>
    </div>
  );
};
